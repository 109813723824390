import {
  BoxGeometry, Color,
  DirectionalLight, Fog,
  GridHelper, LinearEncoding, Mesh,
  MeshBasicMaterial, MeshStandardMaterial, Object3D,
  PerspectiveCamera, PMREMGenerator, PointLight, Raycaster,
  RectAreaLight,
  Scene, sRGBEncoding, UnsignedByteType, Vector2, WebGLRenderer
} from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {getCubeMapTexture} from "./set-scene-environment";
import {MainScene} from "./game/main-scene";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {UnrealBloomPass} from "three/examples/jsm/postprocessing/UnrealBloomPass";
import {ANIMATION_FRAME, DECREMENT_LIVES, INCREMENT_SCORE} from "./utils/constants";
// @ts-ignore
// import { Interaction } from 'three.interaction';
import {AudioLoader} from "./utils/audio-loader";
import * as TWEEN from '@tweenjs/tween.js'
import {GAME_OPTIONS} from "./index";
import Stats from "three/examples/jsm/libs/stats.module";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {loadGLTF} from "./utils/gltf-loader";

const params = {
  exposure: 1,
  bloomStrength: 1.5,
  bloomThreshold: 0,
  bloomRadius: 0
};

const isDev = process.env.NODE_ENV === 'development'

export class Game {
  scene: Scene
  camera: PerspectiveCamera
  renderer: WebGLRenderer
  controls: OrbitControls | null
  composer?: EffectComposer
  renderScene?: RenderPass
  bloomPass?: UnrealBloomPass
  // interaction: Interaction
  score = 0;
  lives = 3;
  maxScore = 200
  private mainScene: MainScene;
  lastFrameDate = 0
  private lastFrame: number | undefined;
  private stats: Stats;
  private options: typeof GAME_OPTIONS;
  private pmremGenerator: PMREMGenerator;

  constructor(containerId: string, options: typeof GAME_OPTIONS) {
    this.options = options
    this.scene = new Scene()
    this.camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    this.renderer = new WebGLRenderer({antialias: true, alpha: true});
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.setClearColor(0xffffff, .5); // 0xcccccc
    // this.renderer
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.pmremGenerator = new PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();
    // this.renderer = new WebGLRenderer({alpha: true, antialias: true, powerPreference: 'high-performance'})
    // this.renderer.setSize(window.innerWidth, window.innerHeight)
    // this.renderer.physicallyCorrectLights = true
    // this.renderer.outputEncoding = sRGBEncoding
    // this.renderer.setClearColor(1,0)
    // this.renderer.setPixelRatio(1)
    // this.renderer.setPixelRatio(2)
    // this.scene.fog = new Fog(options.colors[0], .1, 5)
    // this.scene.fog = new Fog(0xffffff, .1, 5)
    const container = containerId ? document.getElementById(containerId) : document.body
    container?.appendChild(this.renderer.domElement)
    // this.controls = Config.isDev ? new OrbitControls(this.camera, this.renderer.domElement) : null
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    // this.controls.enableRotate = false;
    // this.controls.enableZoom = false;
    // this.interaction = new Interaction(this.renderer, this.scene, this.camera)
    AudioLoader.init(this.camera)
    this.maxScore = options.maxScore
    // this.addLights()

    this.stats = Stats();
    this.stats.showPanel( 1 ); // 0: fps, 1: ms, 2: mb, 3+: custom
    document.body.appendChild( this.stats.dom );

    window.addEventListener('resize', () => onWindowResize(), false)

    const onWindowResize = () => {
      this.camera.aspect = window.innerWidth / window.innerHeight
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(window.innerWidth, window.innerHeight)
      this.render()
    }

    this.mainScene = new MainScene(this.scene, this.renderer, this.camera, this.options)
    this.mainScene.init().then(scene => {
      this.animate();
      this.animateCamera(() => this.mainScene.start())
    })

    // if (isDev) {
    //   const gridHelper = new GridHelper();
    //   this.scene.add( gridHelper );
    // }

    this.score = 0

    this.scene.addEventListener(INCREMENT_SCORE, () => this.incrementScore())
    this.scene.addEventListener(DECREMENT_LIVES, () => this.decrementLives())

    this.controls?.addEventListener('click', e => {
      console.log(e)
    })
    this.updateEnvironment()

    // loadGLTF('models/all.gltf').then(e => {
    //   if (e?.scene) this.scene.add(e.scene)
    //   this.updateTextureEncoding()
    // })
  }

  render() {
    this.renderer.render(this.scene, this.camera)
  }

  animateCamera(onComplete: () => void) {
    const tween = new TWEEN.Tween({ x: 3, y: 3, z: 0 })
    //this.camera.lookAt(0,0,0)
    tween
      .to({ x: 0, y: 0, z: 3 })
      .duration(2000)
      .onUpdate(({ x, y, z }) => {
        this.camera.position.set(x,y,z)
        this.camera.lookAt(0,.05,0)
      })
      .onComplete(onComplete)
      .start()

  }

  animate(frame?: number) {
    this.stats.begin()
    TWEEN.update()
    const delta = (Date.now() - this.lastFrameDate) / 1000
    const fps = 1 / delta;

    // if (fps < 60) {
    //   this.renderer.setPixelRatio(1.5 * fps / 60)
    // } else {
    //   if (this.renderer.pixelRatio < 2) {
    //     this.renderer.setPixelRatio(2)
    //   }
    // }
    this.lastFrameDate = Date.now()
    // console.log(this.stats.getFps = function () { return this._fps })

    this.scene.dispatchEvent({ type: ANIMATION_FRAME, frame })

    this.controls?.update()

    // this.renderer.setPixelRatio(1)

    this.render()
    this.stats.end()
    // setTimeout(() => {
      requestAnimationFrame((frame) => this.animate(frame))
    // }, 1000 / 50)
  }

  async getEnvironment() {
    const env = await getCubeMapTexture(this.renderer, {path: 'venice_sunset_1k.hdr'})
    return env
  }

  private setScore(score: number) {
    this.score = score
    this.onScoreUpdate(score)
    if (score >= this.maxScore) {
      this.onGameOver(score)
      this.stopGame()
    }
    this.mainScene.setScore(score, this.maxScore)
  }

  private setLives(lives: number) {
    this.lives = lives
    this.onLivesUpdate(lives)
    if (lives <= 0) {
      this.onGameOver(this.score)
      this.stopGame()
    }
  }

  public onScoreUpdate(score: number) {}

  public onLivesUpdate(lives: number) {}

  public onGameOver(score: number) {}

  decrementLives() {
    this.setLives(this.lives - 1)
  }

  incrementScore() {
    this.setScore(this.score + 1)
  }

  private stopGame() {
    this.mainScene.stopAnimations()
  }

  private updateTextureEncoding () {
    const encoding = sRGBEncoding
    traverseMaterials(this.scene, (material: MeshStandardMaterial) => {
      if (material.map) material.map.encoding = encoding;
      if (material.emissiveMap) material.emissiveMap.encoding = encoding;
      if (material.map || material.emissiveMap) material.needsUpdate = true;
    });
  }

  private addLights() {
    // TODO: Env or Lights?
    const pLight1 = new PointLight(0xffffff)
    pLight1.intensity = .1
    pLight1.power = 20
    pLight1.position.set(0, 1, 0)
    this.scene.add(pLight1)

    const pLight2 = new PointLight(0xffffff)
    pLight2.intensity = .1
    pLight2.power = 20
    pLight2.position.set(0, -1, 0)
    this.scene.add(pLight2)

    const light1 = new DirectionalLight(0xffffff)
    light1.intensity = .8
    light1.position.set(0, -5, 5)
    light1.lookAt(0, 0, 0)
    this.scene.add(light1)

    const light2 = new DirectionalLight(0xffffff)
    light2.intensity = .8
    light2.position.set(-5, 5, 5)
    light2.lookAt(0, 0, 0)
    this.scene.add(light2)

    const light3 = new DirectionalLight(0xffffff)
    light3.intensity = .8
    light3.position.set(5, 5, 5)
    light3.lookAt(0, 0, 0)
    this.scene.add(light3)
  }

  private updateEnvironment () {
    this.getCubeMapTexture().then(( { envMap } ) => {
      this.scene.environment = envMap;
      // this.scene.background = this.state.background ? envMap : null;
    });

  }

  private getCubeMapTexture ( path = 'venice_sunset_1k.hdr' ) {
    // no envmap
    if ( ! path ) return Promise.resolve( { envMap: null } );

    return new Promise( ( resolve, reject ) => {

      new RGBELoader()
        .setDataType( UnsignedByteType )
        .load( path, ( texture ) => {
          const envMap = this.pmremGenerator.fromEquirectangular( texture ).texture;
          this.pmremGenerator.dispose();

          resolve( { envMap } );

        }, undefined, reject );

    });

  }
}

function traverseMaterials (object: Object3D, callback: any) {
  object.traverse((node) => {
    if (node instanceof Mesh) {
      const materials = Array.isArray(node.material)
        ? node.material
        : [node.material];
      materials.forEach(callback);
    }
  });
}
