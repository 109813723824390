import {
  Color,
  Mesh,
  MeshLambertMaterial,
  MeshStandardMaterial,
  PlaneGeometry,
  Scene, Texture,
  TextureLoader, Vector2,
  Vector3
} from "three";
import TWEEN, { Tween } from "@tweenjs/tween.js";

interface CouponOptions {
  color?: Color;
  imgUrl: string;
  position: Vector3
}

export class Coupon {
  textureLoader = new TextureLoader()
  private material: MeshStandardMaterial;
  private geometry: PlaneGeometry;
  private scale = .5
  mesh: Mesh<PlaneGeometry,MeshStandardMaterial>;
  position = new Vector2(-.77, 1.65) // x = -.9, y = 2.175
  private colorAnimation: Tween<any>
  disabledColor = new Color(0x000000)
  enabledColor: Color

  constructor(private scene: Scene) {}

  init(color? = '#ff0000', imgUrl? = 'images/coupon.png') {
    console.log(imgUrl)
    this.enabledColor = new Color(color)
    this.material = new MeshStandardMaterial({
      map: this.textureLoader.load(imgUrl, tex => {
        console.log(tex.image.width, tex.image.height)
        tex.needsUpdate = true;
        this.mesh.scale.set(this.scale, this.scale * tex.image.height / tex.image.width, this.scale);
      }),
      color: new Color(this.disabledColor),
      transparent: true
    });
    this.geometry = new PlaneGeometry(1);
    // setGradient(this.geometry, 'y', false);
    this.mesh = new Mesh(this.geometry, this.material);
    this.setPosition(this.position)
    this.scene.add(this.mesh);
    return this.mesh
  }

  setColor(color: Color) {
    this.material.color = color
  }

  setPosition(position: Vector2) {
    this.mesh.position.set(position.x, position.y, -.2)
  }

  setTexture(gameImgUrl: string) {
    // this.texture = this.textureLoader.load(gameImgUrl, texture => {
      // console.log(texture.image.width / texture.image.height)
      // const ratio = texture.image.width / texture.image.height
      // this.geometry.scale(10, 1, 1)
    // })

    // this.texture.image
    // this.material.map = this.textureLoader.load(gameImgUrl)
  }

  animateColor() {
    this.stopColorAnimation()
    const tween = new TWEEN.Tween({ color: this.enabledColor })
    this.colorAnimation = tween
      .delay(200)
      .duration(200)
      .repeat(Infinity)
      .yoyo(true)
      .to({ color: this.disabledColor })
      .onUpdate(({ color }) => {
        this.setColor(color)
      })
      .start()
    return tween
  }

  stopColorAnimation() {
    return this.colorAnimation?.stop()
  }
}
