import {
  BufferGeometry,
  Color,
  Light,
  Mesh,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Object3D,
  PointLight,
  Scene
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {ANIMATION_FRAME} from "../utils/constants";
import TWEEN from "@tweenjs/tween.js";

export class Machine {
  private loader = new GLTFLoader()
  private bulbs: Mesh<BufferGeometry, MeshStandardMaterial>[] = []
  private currBulbLight: PointLight | null = null
  private currBulbIdx = 0
  frameMesh: Mesh<BufferGeometry, MeshStandardMaterial> | null = null

  constructor(private scene: Scene) {}

  async init() {
    const [model] = await Promise.all([
      this.getModel(),
      this.getLights()
    ])
    if (model) {
      this.scene.add(model.scene)
    }
    // if (lights) {
    //   lights.scene?.traverse(node => {
    //     const light = node as Light
    //     light.intensity = 10
    //     light.color.set(0xffffff)
    //   })
    //   this.scene.add(lights.scene)
    // }
    this.scene.traverse(node => {
      const obj = node as Mesh<BufferGeometry, MeshStandardMaterial>
      if (obj.material) obj.material = obj.material.clone()
      console.log(obj.name)
      if (obj.name.startsWith('Lamp')) {
        if (obj.position.y < 1.8 && obj.position.y > -1.8) {
          // Model frame is curved so offset bulbs.
          obj.position.setZ(.1)
        }
        // this.addBulbLight(obj)
        this.bulbs.push(obj)
      } else if (obj.name === 'Frame') {
        // console.log(obj)
        // const material = new MeshPhongMaterial({
        //   color: obj.material.color
        // })
        // obj.material = material
        // obj.material.metalness = 1
        // obj.material.roughness = 1
        // obj.material.emissive.set(0x000000)
        // obj.material.color.set(0x000000)
        this.frameMesh = obj
      }
    })
    // this.animateBulbsLights()
    return this
  }

  async getModel() {
    try {
      const model = await this.loader.loadAsync('models/all.gltf')
      return model
    } catch (e) {
      console.log(e)
      return null
    }
  }

  async getLights() {
    try {
      const model = await this.loader.loadAsync('models/lights.gltf')
      return model
    } catch (e) {
      console.log(e)
      return null
    }
  }

  setFrameColor(color: Color) {
    console.log(this.frameMesh, color.getHexString())
    this.frameMesh?.material?.color.set(color)
  }

  setBulbsColor(color: Color) {
    // color = new Color(0xffffff)
    this.bulbs.map(b => b.material.color.set(color))
  }

  addBulbLight(bulb: Mesh<BufferGeometry, MeshStandardMaterial>) {
    // Unset emmission
    bulb.material.transparent = true
    bulb.material.emissive.set(0xFF83E8)
    const light = new PointLight(0xFF83E8, .2, .2)
    light.power = 5
    light.position.set(bulb.position.x, bulb.position.y, bulb.position.z)
    this.scene.add(light)
    // bulb.material.transparent = true
    // //this.frameMesh?.material.color
    // const light = new PointLight(0xffffff)
    // light.intensity = .5
    // light.power = 2
    // const { x, y, z } = bulb.position
    // light.position.set(x, y, z + .03)
    // this.scene.add(light)
    // return light
  }

  animateBulbsLights() {
    let lights = []
    new TWEEN.Tween({})
      .duration(200)
      .repeat(Infinity)
      .onRepeat(() => {
        if (lights.length) {
          lights.map(l => l.parent.remove(l))
          lights = []
        } else {
          lights = this.bulbs.map(bulb => this.addBulbLight(bulb))
        }
      })
      .start()
    // let start = 0
    // this.scene.addEventListener(ANIMATION_FRAME, async (e) => {
    //   if (e.frame - start > 50) {
    //     start = e.frame
    //     try {
    //       const obj = this.bulbs[this.currBulbIdx]
    //       const light = new PointLight(0xFFFF99)
    //       //obj.material.emissive
    //       light.intensity = 1
    //       light.power = 20
    //       const { x, y, z } = obj.position
    //       light.position.set(x, y, z + .03)
    //       this.scene.add(light)
    //       this.currBulbIdx = this.currBulbIdx > this.bulbs.length - 2 ? 0 : this.currBulbIdx + 1
    //       this.currBulbLight?.parent?.remove(this.currBulbLight)
    //       this.currBulbLight = light
    //     } catch (e) {
    //       console.log(e)
    //     }
    //   }
    //   // console.log(e)
    // })
    // return setInterval(() => {
    //   try {
    //     const obj = this.bulbs[this.currBulbIdx]
    //     const light = new PointLight(obj.material.emissive)
    //     const { x, y, z } = obj.position
    //     light.position.set(x, y, z + .1)
    //     this.scene.add(light)
    //     this.currBulbIdx = this.currBulbIdx > this.bulbs.length - 2 ? 0 : this.currBulbIdx + 1
    //     this.currBulbLight?.parent?.remove(this.currBulbLight)
    //     this.currBulbLight = light
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }, 50)
  }
}
